import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

// external-global styles must be imported in your JS.
import normalizeCss from 'normalize.css';
import s from './Layout.css';
import Header from '../Header';
import Feedback from '../Feedback';
import Footer from '../Footer';

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    isPayalHeader: PropTypes.bool
  };

  render() {
    const { isPayalHeader } = this.props;
    return (
      <div>
        {!isPayalHeader && <Header />}
        {this.props.children}
        {/* <Feedback />*/}
        <Footer />
      </div>
    );
  }
}

export default withStyles(normalizeCss, s)(Layout);
