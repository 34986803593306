import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';

import {
  SET_PAYOUT_START,
  SET_PAYOUT_SUCCESS,
  SET_PAYOUT_ERROR,
  UPDATE_PAYMENT_STATUS_START,
  UPDATE_PAYMENT_STATUS_SUCCESS,
  UPDATE_PAYMENT_STATUS_ERROR
} from '../../../constants';
import getPaymentMethodsQuery from '../../../routes/site-admin/paymentGateway/getPaymentMethods.graphql';
import updatePaymentMutation from './updatePayment.graphql';
// import getPayoutList from '../../../routes/site-admin/autoPayout/getPayoutList.graphql';

export function updatePayoutStatus(id, isBanStatus, variables, referQuery) {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: SET_PAYOUT_START,
    });

    try {
      let getPayoutList = gql`
      query getPayoutList($currentPage: Int, $searchList: String, $payoutId: String){
        getPayoutList(currentPage: $currentPage, searchList:$searchList, payoutId: $payoutId){
         count
          bookingData{
            id
            tripStatus
            currency
            driverDetails{
              firstName
              userData{
                email
              }
            }
            totalFare
            isPayoutPaid
            isBanStatus
            paymentType
            driverTotalFare
          }
        }
      }
      `

      let mutation = gql`
        mutation updatePayout ($id: Int!, $isBanStatus: Boolean!){
          updatePayout(id: $id, isBanStatus: $isBanStatus){
              status
              errorMessage
            }
        }`;

      const { data } = await client.mutate({
        mutation,
        variables: { id, isBanStatus },
        // refetchQueries: [{ query: getPayoutList, variables: { currentPage: '1', searchList: '', payoutId } }]
      });

      if (data.updatePayout.status === 200) {

        dispatch({
          type: SET_PAYOUT_SUCCESS,
        });
        if (referQuery) referQuery(variables);
        toastr.success("Success!", "Payout status has been updated successfully.");

      } else {

        dispatch({
          type: SET_PAYOUT_ERROR,
        });

        toastr.error("Failed!", "Failed to Update status");

        return false;
      }
    } catch (error) {

      dispatch({
        type: SET_PAYOUT_ERROR,
        payload: {
          error
        }
      });

      toastr.error("Failed!", "Failed to change Currency status");

      return false;
    }
    return true;
  };
}

export function updatePaymentMethod(id, status) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: UPDATE_PAYMENT_STATUS_START,
    });
    try {

      const { data } = await client.mutate({
        mutation: updatePaymentMutation,
        variables: { id, status },
        refetchQueries: [{ query: getPaymentMethodsQuery }]
      });
      if (data.updatePayment.status === 200) {
        dispatch({
          type: UPDATE_PAYMENT_STATUS_SUCCESS,
        });
        toastr.success("Success!", "Payment gateway status has been updated successfully.");
      } else {
        dispatch({
          type: UPDATE_PAYMENT_STATUS_ERROR,
          payload: {
            error: data.updatePayment && data.updatePayment.errorMessage
          }
        });
        toastr.error("Failed!", data.updatePayment && data.updatePayment.errorMessage ? data.updatePayment.errorMessage : 'Failed to update status!');
        return false;
      }
    } catch (error) {
      dispatch({
        type: UPDATE_PAYMENT_STATUS_ERROR,
        payload: {
          error
        }
      });
      toastr.error("Failed!", "Failed to update status!");
      return false;
    }
    return true;
  };
}
