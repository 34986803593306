import React, { Component } from 'react'
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table, FormControl } from 'react-bootstrap';
import cx from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

//Components
import CurrencyConverter from '../../CurrencyConverter';
import CustomPagination from '../../CustomPagination';
import Loader from '../../../components/Common/Loader';
import messages from '../../../locale/messages';
import Link from '../../Link/Link';

//Actions
import { updatePayoutStatus } from '../../../actions/siteadmin/Payout/updatePayout';
import { updateCashPayout } from '../../../actions/siteadmin/Payout/updateCashPayout';

//Helpers
import debounce from '../../../helpers/debounce';
import { capitalizeFirstLetter } from '../../../helpers/capitalizeFirst';

//Images
import ExportImage from '../../../../public/Icons/export.png';
import ExportImageDark from '../../../../public/Icons/exportDark.png';

//Css
import s from './AutoPayout.css';
import bt from '../../../components/commonStyle.css';

export class AutoPayoutList extends Component {
    static propTypes = {
        getPayoutData: PropTypes.object,
        updatePayoutStatus: PropTypes.any.isRequired,
    }
    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            searchList: "",
            payoutId: "",
            selectedVerification: [],
            successVerification: [],
        }
        this.paginationData = this.paginationData.bind(this);
        this.holdPayout = this.holdPayout.bind(this);
        this.cashPayout = this.cashPayout.bind(this);
        this.handleKeywordSearch = debounce(this.handleKeywordSearch.bind(this), 250);
    }
    handleKeywordSearch(searchList) {
        const { getPayoutData: { refetch } } = this.props
        const { payoutId } = this.state;

        let variables = {
            currentPage: 1,
            searchList: searchList,
            payoutId
        }
        this.setState(variables)
        refetch(variables)
    }
    paginationData(currentPage) {
        const { getPayoutData: { refetch } } = this.props;
        const { payoutId } = this.state;

        let variables = { currentPage, payoutId };
        this.setState({ currentPage, payoutId });
        refetch(variables);
    }
    async cashPayout(id, value, currentPage) {
        const { updateCashPayout, getPayoutData: { refetch } } = this.props;
        const { payoutId, searchList } = this.state;

        let payoutStatus = value == 'true' ? true : false;
        this.changeLoaderState('addUpdateStatus', id);
        await updateCashPayout(id, payoutStatus, currentPage, payoutId, this.changeLoaderState)
        let variables = { currentPage, payoutId, searchList };
        this.setState(variables);
        // refetch(variables);
    }
    async holdPayout(e, id, currentPage) {
        const { updatePayoutStatus, getPayoutData: { refetch } } = this.props;
        const { payoutId } = this.state;
        let variables = { currentPage, payoutId };
        this.setState(variables);
        let isBanStatus = (e.target.value);
        if (isBanStatus == "true" || isBanStatus == "false") {
            isBanStatus = (isBanStatus == "true") ? true : false;
            await updatePayoutStatus(id, isBanStatus, variables, refetch);
        }
    }
    payoutFilter = (payoutId) => {
        const { getPayoutData: { refetch } } = this.props;
        const { currentPage, searchList } = this.state;
        this.setState({ currentPage: 1, payoutId });
        refetch({ currentPage: 1, searchList, payoutId });
    };

    changeLoaderState = (type, value) => {
        const { selectedVerification, successVerification } = this.state;
        let variables = {};
        if (type === 'addUpdateStatus') {
            variables = { selectedVerification: [...selectedVerification, value] };
        }
        if (type === 'removeUpdateStatus') {
            let index = selectedVerification.findIndex(i => i === value);
            if (index === -1) return '';
            let data = [...selectedVerification];
            data.splice(index, 1)
            variables = { selectedVerification: data };
        }
        if (type === 'successUpdateStatus') {
            variables = { successVerification: [...successVerification, { id: value }] };
        }
        this.setState(variables)
    };

    renderPayout = (value) => {
        const { intl: { formatMessage } } = this.props;
        const { selectedVerification, successVerification, currentPage } = this.state;

        if (!value || !value.id) return <span>-</span>;

        let isSuccess = successVerification.find(item => item.id === value.id);

        if (isSuccess && isSuccess.id) return <span>{formatMessage(messages.paid)}</span>;

        if (selectedVerification.includes(value.id)) return <span className={s.processingtext}>{formatMessage(messages.processingLabel)}</span>;

        return <>{

            value.isPayoutPaid == '1' ? <span>{formatMessage(messages.paid)}</span>
                :
                ((value.paymentType === 1 || (value?.driverDetails?.payoutData?.methodId == 2)) ? (
                    <select value={value.isPayoutPaid} onChange={(e) => { this.cashPayout(value.id, e.target.value, currentPage) }} className={bt.selectInput}>
                        <option value={true}>{formatMessage(messages.paid)}</option>
                        <option value={false}>{formatMessage(messages.unpaid)}</option>
                    </select>
                ) : "-")
        }</>
    };

    render() {
        const { getPayoutData, loading, selectedTheme } = this.props;
        const { currentPage, searchList, payoutId } = this.state;
        const { formatMessage } = this.props.intl;

        return (
            <div className={cx(s.widthInner, 'bgBlackTwo')}>
                <div className={cx("autoPayoutFlexContainer")}>
                    <div className="autoPayoutButtonFlexContainer">
                        <div className={cx(s.searchInput, 'searchInputRTL', "autoPayoutSearchInput", "autoPayoutSearchInputRtl")}>
                            <FormControl type='text' placeholder={formatMessage(messages.searchOnly)} onChange={(e) => this.handleKeywordSearch(e.target && e.target.value)} className={bt.formControlInput} />
                          </div>  
                          <div className={cx(s.searchInput, 'searchInputRTL', "autoPayoutSearchInput", "autoPayoutSearchInputRtl")}>
                            <FormControl as={"select"} placeholder={formatMessage(messages.searchOnly)} onChange={(e) => this.payoutFilter(e.target && e.target.value)} className={bt.formControlInput} >
                                <option value={""}>{formatMessage(messages.all)}</option>
                                <option value={"5"}>{formatMessage(messages.paypalLabel)} </option>
                                <option value={"2"}>{formatMessage(messages.stripeLabel)} </option>
                            </FormControl>
                        </div>
                    </div>
                    <div className={cx(s.exportTextSection, 'textAlignLeftRTL')}>
                        {
                            getPayoutData && getPayoutData.getPayoutList && getPayoutData.getPayoutList.bookingData && getPayoutData.getPayoutList.bookingData.length > 0 && <a
                                href={`/export-admin-data?type=payout&keyword=${searchList || ''}&payoutId=${payoutId || ''}`}
                                className={cx('pull-right', s.textDecorationNone)}>
                                <span className={cx(s.vtrMiddle, s.exportText, 'exportTextRTL')}><FormattedMessage {...messages.exportDataIntoCSV} /></span>
                                {
                                    (selectedTheme && selectedTheme === "light") ?
                                        <img src={ExportImage} className={cx(s.exportImg)} /> : <img src={ExportImageDark} className={cx(s.exportImg)} />
                                }
                            </a>
                        }
                    </div>
                </div>
                <div class="clearfix"></div>
                {
            loading && <div>
                <Loader type="circle" />
            </div>
        }
        {
            !loading &&
            <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewResponsiveTable', 'topScrollbarTable', 'whiteDropdown', "tableMobileResponseRtl")}>
                <Table className={cx("table", 'topScrollbarTableContent')}>
                    <thead>
                        <tr>
                            <th scope="col"><FormattedMessage {...messages.id} /></th>
                            <th scope="col"><FormattedMessage {...messages.driverName} /></th>
                            <th scope="col"><FormattedMessage {...messages.email} /></th>
                            <th scope="col"><FormattedMessage {...messages.payoutAmount} /></th>
                            <th scope="col"><FormattedMessage {...messages.payoutStatus} /></th>
                            <th scope="col"><FormattedMessage {...messages.action} /></th>
                            <th scope="col"><FormattedMessage {...messages.holdPayout} /></th>
                            <th scope="col"><FormattedMessage {...messages.details} /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            getPayoutData && getPayoutData.getPayoutList && getPayoutData.getPayoutList.bookingData && getPayoutData.getPayoutList.bookingData.length > 0 && getPayoutData.getPayoutList.bookingData.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td data-label={formatMessage(messages.id)}>{item.id}</td>
                                        <td data-label={formatMessage(messages.driverName)}>{item && item.driverDetails && capitalizeFirstLetter(item.driverDetails.firstName)}</td>
                                        <td data-label={formatMessage(messages.email)}>{item && item.driverDetails && item.driverDetails.userData && item.driverDetails.userData.email}</td>
                                        <td data-label={formatMessage(messages.payoutAmount)}><CurrencyConverter from={item.currency} amount={item.driverTotalFare} /></td>
                                        <td data-label={formatMessage(messages.payoutStatus)}>{(item.paymentType === 1) ? formatMessage(messages['processedCash']) : (item.isPayoutPaid && item.isPayoutPaid == true) ? formatMessage(messages['completed']) : formatMessage(messages['pending'])}</td>
                                        <td data-label={formatMessage(messages.action)}>{this.renderPayout(item)}</td>
                                        {item.isPayoutPaid == '1' ? <td data-label={formatMessage(messages.holdPayout)}>{formatMessage(messages.notRequiredMessage)}</td> :
                                            <td data-label={formatMessage(messages.holdPayout)}>
                                                {
                                                    (item.paymentType === 1 || item?.driverDetails?.payoutData?.methodId == 2) ? "-" :
                                                        <select value={item.isBanStatus} onChange={(e) => { this.holdPayout(e, item.id, currentPage) }} className={bt.selectInput}>
                                                            <option value={true}>{formatMessage(messages.yes)}</option>
                                                            <option value={false}>{formatMessage(messages.no)}</option>
                                                        </select>
                                                }

                                            </td>
                                        }
                                        <td data-label={formatMessage(messages.details)}><Link to={'/siteadmin/payout/view/' + item.id}><FormattedMessage {...messages.view} /></Link></td>
                                    </tr>
                                )
                            })
                        }
                        {
                            getPayoutData && getPayoutData.getPayoutList && getPayoutData.getPayoutList.bookingData && getPayoutData.getPayoutList.bookingData.length == 0 && (
                                <tr>
                                    <td colspan="12" className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </div>
        }
        {
            getPayoutData && getPayoutData.getPayoutList && getPayoutData.getPayoutList.bookingData && getPayoutData.getPayoutList.bookingData.length > 0
                && <div className={cx(bt.space5, bt.spaceTop5)}>
                    <CustomPagination
                        total={getPayoutData.getPayoutList.count}
                        currentPage={currentPage}
                        defaultCurrent={1}
                        defaultPageSize={10}
                        change={this.paginationData}
                        paginationLabel={formatMessage(messages.drivPayout)}
                    />
                </div>
        }
            </div >
        )
    }
}
const mapDispatch = {
    updatePayoutStatus,
    updateCashPayout
};
const mapState = (state) => ({
    loading: state.intl.loading,
    selectedTheme: state.currency.theme
});
export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(AutoPayoutList)));

