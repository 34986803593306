import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

//Components
import messages from '../../locale/messages';
import StarRating from '../../components/StarRating';

//Config
import { profilePhotouploadDir, api, categoryUploadDir } from '../../config';

class RiderDetails extends Component {
    render() {
        const { data, siteName } = this.props
        return (
            <div>
                {
                    data && data.riderDetails &&
                    <div>
                        {
                            data.riderDetails.picture &&
                            <img
                                src={api.apiEndpoint + profilePhotouploadDir + data.riderDetails.picture}
                                width={50}
                                height={50}
                                alt={siteName}
                            />
                        }
                        {
                            data.riderDetails.firstName && <div>{data.riderDetails.firstName}</div>
                        }
                        {
                            <StarRating value={data?.riderDetails?.userData?.overallRating || 0} name={'review'} />
                        }
                        {
                            data?.driverDetails?.vehicleDetails?.category?.categoryImage && <img
                                src={api.apiEndpoint + categoryUploadDir + data.driverDetails.vehicleDetails.category.categoryImage}
                                width={50}
                                height={50}
                                alt={siteName}
                            />
                        }
                        {
                            data?.driverDetails?.vehicleDetails?.category?.categoryName && <span>{data?.driverDetails?.vehicleDetails?.category?.categoryName}</span>
                        }
                        {
                            data?.tripStatus == "completed" && <span><FormattedMessage {...messages.completed} /></span>
                        }
                        {
                            data?.pickUpLocation && <div>{data.pickUpLocation}</div>
                        }
                        {
                            data.bookingLocations && data.bookingLocations.length > 1 ? data.bookingLocations.map((locationData, idx) => {
                                return (
                                    <div key={idx}>
                                        <span >
                                            {locationData.location}
                                        </span>
                                    </div>
                                )
                            }) : <span >
                                {data.dropOffLocation}
                            </span>

                        }
                    </div>

                }
            </div>
        )
    }
};

export default injectIntl(RiderDetails);