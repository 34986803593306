// General
import React, { Component } from 'react';
// Style
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages';
import s from './PayoutModal.css';
import {
  Modal
} from 'react-bootstrap';
// Redux
import { connect } from 'react-redux';
import { updatePayoutModal } from '../../../actions/siteadmin/modalActions';


class PayoutModal extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { updatePayoutModal, isPayoutModal, payoutData } = this.props;
    let payoutType = "Stripe";
    if (payoutData && payoutData.methodId == 5) payoutType = "Paypal";
    return (
      <div>
        <Modal show={isPayoutModal} onHide={() => updatePayoutModal(payoutData, false)} className={cx('wooberlyModal')}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h2>
                <FormattedMessage {...messages.payoutInformation} />
              </h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body bsClass={s.logInModalBody}>
            <div className={s.root}>
              <div className={s.container}>
                {
                  payoutData && payoutData.id ? <div>
                    <p className='textBreak textAlignRightRTL'>
                      <span className='informHeadingText'>
                        <FormattedMessage {...messages.payoutLabel} />:
                      </span>
                      <span>
                        &nbsp;{payoutType || "-"}
                      </span>
                    </p>
                    <p className='textBreak textAlignRightRTL'>
                      <span className='informHeadingText'>
                        <FormattedMessage {...messages.country} />:
                      </span>
                      <span>
                        &nbsp;{payoutData.country || "-"}
                      </span>
                    </p>
                    <p className='textBreak textAlignRightRTL'>
                      <span className='informHeadingText'>
                        <FormattedMessage {...messages.addressLine1} />:
                      </span>
                      <span>
                        &nbsp;{payoutData.address1 || "-"}
                      </span>
                    </p>
                    <p className='textBreak textAlignRightRTL'>
                      <span className='informHeadingText'>
                        <FormattedMessage {...messages.addressLine2} />:
                      </span>
                      <span>
                        &nbsp;{payoutData.address2 || "-"}
                      </span>
                    </p>
                    <p className='textBreak textAlignRightRTL'>
                      <span className='informHeadingText'>
                        <FormattedMessage {...messages.cityLabel} />:
                      </span>
                      <span>
                        &nbsp;{payoutData.city || "-"}
                      </span>
                    </p>
                    <p className='textBreak textAlignRightRTL'>
                      <span className='informHeadingText'>
                        <FormattedMessage {...messages.stateProvince} />:
                      </span>
                      <span>
                        &nbsp;{payoutData.state || "-"}
                      </span>
                    </p>
                    <p className='textBreak textAlignRightRTL'>
                      <span className='informHeadingText'>
                        <FormattedMessage {...messages.zipCode} />:
                      </span>
                      <span>
                        &nbsp;{payoutData.zipcode || "-"}
                      </span>
                    </p>
                    {payoutData.methodId == 2 &&
                      <p className='textBreak textAlignRightRTL'>
                        <span className='informHeadingText'>
                          <FormattedMessage {...messages.accountHolderFirstName} />:
                        </span>
                        <span>
                          &nbsp;{payoutData.firstName || "-"}
                        </span>
                      </p>
                    }
                    {payoutData.methodId == 2 &&
                      <p className='textBreak textAlignRightRTL'>
                        <span className='informHeadingText'>
                          <FormattedMessage {...messages.accountHolderLastName} />:
                        </span>
                        <span>
                          &nbsp;{payoutData.lastName || "-"}
                        </span>
                      </p>
                    }
                    <p className='textBreak textAlignRightRTL'>
                      <span className='informHeadingText'>
                        <FormattedMessage {...messages[payoutData.methodId == 2 ? "accountNumber" : "email"]} />:
                      </span>
                      <span>
                        &nbsp;{payoutData.payEmail || "-"}
                      </span>
                    </p>
                    {payoutData.methodId == 2 &&
                      <p className='textBreak textAlignRightRTL'>
                        <span className='informHeadingText'>
                          <FormattedMessage {...messages.ibanNumber} />:
                        </span>
                        <span>
                          &nbsp;{payoutData.IBANNumber || "-"}
                        </span>
                      </p>
                    }
                  </div> : <div className='text-center textBreak'><FormattedMessage {...messages.noPayout} /></div>
                }
              </div>
            </div>

          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
const mapState = (state) => ({
  isPayoutModal: state.adminModalStatus.isPayoutModal,
  payoutData: state.adminModalStatus.payoutData,
});
const mapDispatch = {
  updatePayoutModal
};
export default withStyles(s)(connect(mapState, mapDispatch)(PayoutModal));
