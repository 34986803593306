import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import messages from '../../../locale/messages';
import AdminEmails from './AdminEmails';

function action({ store, intl }) {
    const title = intl.formatMessage(messages.addAdminEmail);

    //From Redux Store
    let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
    let isSuperAdmin = store.getState().runtime.isSuperAdmin;

    if (!isAdminAuthenticated) {
        return {  redirect: '/login' };
    }

    if (!isSuperAdmin) {
        return { redirect: '/siteadmin' };
    }

    return {
        title,
        component: (
            <AdminLayout><AdminEmails title={title} /></AdminLayout>
        ),
    }
}

export default action;