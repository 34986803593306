import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TagsInput } from 'react-tag-input-component';
import { change, Field } from 'redux-form';
import { Button, Row, Col, Container } from 'react-bootstrap';
import cx from 'classnames';
import messages from '../../../locale/messages';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { validateTag, getAllEmail } from './validateEmail';
import submit from './submit';
import withStyles from 'isomorphic-style-loader/withStyles';
import validate from './validate';
import { toastr } from 'react-redux-toastr';

import cs from '../../../components/commonStyle.css';

class AdminEmailForm extends Component {
    static propTypes = {
        prop: PropTypes
    }
    constructor(props) {
        super(props);
        this.state = {
            email: [],
            isChangeComplete: false,
            isInputDisable: false
        }
    }
    componentDidMount() {
        const { getAllAdminEmail } = this.props;
        const email = getAllEmail({ getAllAdminEmail })
        this.setState({ email });
    }

    handleOnChange = (e) => {
        const { change, getAllAdminEmail } = this.props;
        const { isChangeComplete } = this.state;
        if (getAllAdminEmail?.results.length == 0 && !isChangeComplete) {
            change('email', e)
            this.setState({ isChangeComplete: true });
        } else {
            change('email', e)
        }
        this.setState({ email: e })
    }

    handleKeyUp = (e) => {
        this.setState({ isInputDisable: e.target.value.length > 0 ? true : false })
    }

    handleExistingTag = (tag) => {
        tag && toastr.error('Error', 'Your email is already exist')
    };

    renderTagsInput = ({ label, values, meta: { touched, error } }) => {
        const { formatMessage } = this.props.intl;
        return (
            <div className={'adminEmailTags'}>
                <label className={cx(cs.labelText, cs.fontWeightNormal)}>{label}</label>
                <TagsInput
                    value={values}
                    onChange={this.handleOnChange}
                    onExisting={this.handleExistingTag}
                    beforeAddValidate={validateTag}
                    onKeyUp={this.handleKeyUp}
                    placeHolder={label}
                />
                {error && <span className={cs.errorMessage}>{formatMessage(error)}</span>}
                <p className={cs.spaceTop1}>{formatMessage(messages.adminEmailDesc)}</p>
            </div>
        );
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { handleSubmit } = this.props;
        const { email, isInputDisable } = this.state;
        let isDisabled = email.length <= 0;

        return (
            <Container fluid className={cs.spaceTop5}>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <form onSubmit={handleSubmit(submit)} >
                            <Field
                                name="email"
                                component={this.renderTagsInput}
                                values={email}
                                label={formatMessage(messages.adminEmailLabel)}
                            />
                            <div className={cx(cs.displayFlex, cs.alignItemCenter, cs.justifyFlexEnd)}>
                                <Button type="submit" disabled={isInputDisable || isDisabled} className={cs.btnPrimary}>{formatMessage(messages.save)}</Button>
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
        )
    }
}

AdminEmailForm = reduxForm({
    form: "AdminEmailForm",
    validate,
})(AdminEmailForm);

const mapState = (state) => ({});

const mapDispatch = {
    change
};

export default injectIntl(withStyles(cs)(connect(mapState, mapDispatch)(AdminEmailForm)));