import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import ReactGoogleMapLoader from "react-google-maps-loader";
import {
	GoogleMap,
	Marker,
	HeatmapLayer,
	InfoBox,
} from "@react-google-maps/api";
import cx from 'classnames';
import { googleMapAPI } from '../../../../config';
import users from '../../../../../public/Icons/MapMarker/users.png';
import availableDrivers from '../../../../../public/Icons/MapMarker/availableDrivers.png';
import unActivatedDrivers from '../../../../../public/Icons/MapMarker/unActivatedDrivers.png';
import unAvailableDrivers from '../../../../../public/Icons/MapMarker/unAvailableDrivers.png';
import messages from '../../../../locale/messages';
import s from "./GoogleMap.css"

class ShowMap extends Component {
	static defaultProps = {
		zoom: 4,
		height: '400px',
		width: '100%',
		center: { lat: 55.3781, lng: 3.4360 },
		bounds: []
	};


	constructor(props) {
		super(props);
		this.state = {
			mapMarkerPoints: props?.mapMarkerPoints?.length > 0 ? props?.mapMarkerPoints : []
		};
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
		document.addEventListener('touchstart', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
		document.removeEventListener('touchstart', this.handleClickOutside);
	}

	setWrapperRef = (node) => {
		this.wrapperRef = node;
	}

	handleClickOutside = (event) => {
		const { mapMarkerPoints } = this.state;
		if (this.wrapperRef && !this.wrapperRef.contains(event.target) && mapMarkerPoints?.length > 0) {
			const targetMarker = mapMarkerPoints.find(marker => marker.showInfo);
			this.handleMarkerClick(targetMarker?.id, false);
		}
	}

	handleMarkerClick = (targetMarker, isOpen) => {
		this.setState({
			mapMarkerPoints: this.state.mapMarkerPoints.length > 0 &&
				this.state.mapMarkerPoints.map(marker => {
					return {
						...marker,
						showInfo: (marker?.id === targetMarker) && isOpen,
					};
				})
		});
	}

	onLoad = (map) => {
		const { heatMapData, showSection, type } = this.props;
		const { mapMarkerPoints } = this.state;
		let bounds = new google.maps.LatLngBounds();

		if (showSection === 0 && map && mapMarkerPoints && mapMarkerPoints.length > 0) {
			mapMarkerPoints.map((i) => {
				let positionData;
				if (type == 3) {
					positionData = new google.maps.LatLng(parseFloat(i.lat), parseFloat(i.lng))
				} else {
					if (i.userType == 2 && i.isActive == 1 && i.activeStatus == 'active' && i.userStatus == 'active') {
						positionData = new google.maps.LatLng(parseFloat(i.lat), parseFloat(i.lng))
					} else {
						positionData = new google.maps.LatLng(parseFloat(i.profile.lat), parseFloat(i.profile.lng))
					}
				}
				bounds.extend(positionData);
			});
			map.fitBounds(bounds);
		}
		else if (showSection === 1 && map && heatMapData && heatMapData.length > 0) {
			heatMapData.map((i) => {
				bounds.extend(i);
			});
			map.fitBounds(bounds);
		}
	}

	render() {
		const { type, zoom, height, width, center, heatMapData, showSection } = this.props;
		const { formatMessage } = this.props.intl;
		const { mapMarkerPoints } = this.state;
		let mapStyles = [
			{
				featureType: "poi",
				elementType: "geometry",
				stylers: [
					{
						color: "#eeeeee",
					},
				],
			},
			{
				featureType: "poi",
				elementType: "labels.text",
				stylers: [
					{
						visibility: "off",
					},
				],
			},
			{
				featureType: "water",
				elementType: "labels.text.fill",
				stylers: [
					{
						color: "#9e9e9e",
					},
				],
			},
		];

		let options = {
			styles: mapStyles,
			disableDefaultUI: true,
			minZoom: 2,
			maxZoom: 22,
			zoomControl: true,
		}

		if (showSection === 1) {
			options['mapTypeId'] = "satellite"
		} else {
			options['mapTypeId'] = "roadmap"
		}

		return (
			<ReactGoogleMapLoader
				params={{
					key: googleMapAPI,
					libraries: "geometry,drawing,places,visualization"
				}}
				render={googleMaps =>
					googleMaps && (
						<div>
							<GoogleMap
								ref={(map) => this.map = map}
								zoom={zoom}
								center={center}
								mapContainerStyle={{ height, width }}
								options={{
									...options,
									zoomControlOptions: {
										position: google.maps.ControlPosition.LEFT_TOP
									}
								}}
								onLoad={(map) => this.onLoad(map)}
							>
								{
									showSection === 0 && mapMarkerPoints && mapMarkerPoints.length > 0 && mapMarkerPoints.map((position, index) => {
										let positionData, icon = null;
										if (type === '1') {
											if (position.userType === 1) {
												icon = users;
											} else if (position.userType === 2) {
												if (position.userStatus === 'active' && position.isActive === 1 && position.activeStatus == 'active') {
													icon = availableDrivers;
												} else if (position.userStatus === 'active' && position.isActive === 1 && position.activeStatus == 'inactive') {
													icon = unAvailableDrivers;
												} else if (position.userStatus === 'pending' || position.userStatus === 'inactive') {
													icon = unActivatedDrivers;
												}
											}
										} else if (type === '2') {
											icon = users;
										} else if (type === '3') {
											icon = availableDrivers;
										} else if (type === '4') {
											icon = unAvailableDrivers;
										} else if (type === '5') {
											icon = unActivatedDrivers;
										}

										if (type == 3) {
											positionData = new google.maps.LatLng(parseFloat(position.lat), parseFloat(position.lng))
										} else {
											if (position.userType == 2 && position.isActive == 1 && position.activeStatus == 'active' && position.userStatus == 'active') {
												positionData = new google.maps.LatLng(parseFloat(position.lat), parseFloat(position.lng))
											} else {
												positionData = new google.maps.LatLng(parseFloat(position.profile.lat), parseFloat(position.profile.lng))
											}
										}

										return (<Marker
											key={index}
											position={positionData}
											icon={icon}
											onClick={() => this.handleMarkerClick(position?.id, true)}
											zIndex={100 + index}
										>
											{
												position?.showInfo && position?.userType == '2' &&
												<InfoBox
													onCloseClick={() => this.handleMarkerClick(position?.id, false)}
													options={{
														boxStyle: {
															padding: "10px",
															overflow: "hidden auto",
															background: "white",
															width: "200px",
															borderRadius: '6px',
															wordBreak: "break-word",
														},
														alignBottom: true,
														closeBoxURL: '',
														pane: "floatPane",
														pixelOffset: new google.maps.Size(-222, -26),
														enableEventPropagation: true,
													}}
													position={positionData}
													zIndex={330}
												>
													<div ref={this.setWrapperRef}>
														<p className={cx("darkModeMapPara", s.showMapPara, "showMapParaRtl")}><span className={cx(s.showMapHeading)}>{formatMessage(messages.driverIdLabel)}</span>:&nbsp;<span className={cx(s.showMapInnerText)}>{position?.profile?.profileId}</span></p>
														<p className={cx("darkModeMapPara", s.showMapPara, "showMapParaRtl")}><span className={cx(s.showMapHeading)}>{formatMessage(messages.driverName)}</span>:&nbsp;<span className={cx(s.showMapInnerText)}>{position?.profile?.firstName}</span></p>
														<p className={cx("darkModeMapPara", s.showMapPara, "showMapParaRtl")}><span className={cx(s.showMapHeading)}>{formatMessage(messages.phoneNumber)}</span>:&nbsp;<span className={cx(s.showMapInnerText, "showMapInnerPhoneRtl")}>{position?.phoneDialCode} {position?.phoneNumber}</span></p>
													</div>
												</InfoBox>
											}
										</Marker>
										)
									})
								}

								{
									showSection === 1 && heatMapData && heatMapData.length > 0 && <HeatmapLayer
										data={heatMapData}
									/>
								}

							</GoogleMap>
						</ div>
					)
				}
			/>
		)
	}
}

const mapState = state => ({});

const mapDispatch = {
	change
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(ShowMap)));
