import messages from '../../../locale/messages'

const validate = values => {

  const errors = {}


  if (!values.stripePublishableKey) {
    errors.stripePublishableKey = messages.required;
  } else if (values.stripePublishableKey && values.stripePublishableKey.toString().trim() == "") {
    errors.stripePublishableKey = messages.required;
  }

  if (!values.stripeSecretKey) {
    errors.stripeSecretKey = messages.required;
  } else if (values.stripeSecretKey && values.stripeSecretKey.toString().trim() == "") {
    errors.stripeSecretKey = messages.required;
  }

  if (!values.twillioAccountSid) {
    errors.twillioAccountSid = messages.required;
  } else if (values.twillioAccountSid && values.twillioAccountSid.toString().trim() == "") {
    errors.twillioAccountSid = messages.required;
  }

  if (!values.twillioPhone) {
    errors.twillioPhone = messages.required;
  } else if (values.twillioPhone && values.twillioPhone.toString().trim() == "") {
    errors.twillioPhone = messages.required;
  }

  if (!values.twillioAuthToken) {
    errors.twillioAuthToken = messages.required;
  } else if (values.twillioAuthToken && values.twillioAuthToken.toString().trim() == "") {
    errors.twillioAuthToken = messages.required;
  }

  if (!values.maxDistance) {
    errors.maxDistance = messages.required;
  } else if (values.maxDistance && values.maxDistance.toString().trim() == "") {
    errors.maxDistance = messages.required;
  } else if (isNaN(values.maxDistance)) {
    errors.maxDistance = messages.numericValue;
  }

  if (!values.pushNotificationKey) {
    errors.pushNotificationKey = messages.required;
  } else if (values.pushNotificationKey && values.pushNotificationKey.toString().trim() == "") {
    errors.pushNotificationKey = messages.required;
  }

  if (!values.paypalEmail) {
    errors.paypalEmail = messages.required && messages.required;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.paypalEmail)) {
    errors.paypalEmail = messages.emailInvalid && messages.emailInvalid;
  }

  if (!values.paypalClientId) {
    errors.paypalClientId = messages.required;
  } else if (values.paypalClientId.toString().trim() == "") {
    errors.paypalClientId = messages.required;
  }

  if (!values.paypalSecret) {
    errors.paypalSecret = messages.required;
  } else if (values.paypalSecret.toString().trim() == "") {
    errors.paypalSecret = messages.required;
  }

  if (!values.paypalHost) {
    errors.paypalHost = messages.required;
  } else if (values.paypalHost.toString().trim() == "") {
    errors.paypalHost = messages.required;
  }

  if (!values.paypalHostMode) {
    errors.paypalHostMode = messages.required;
  }


  if (!values.shortTripThresHoldValue || values.shortTripThresHoldValue.toString().trim() == "" || values.shortTripThresHoldValue == 0) {
    console.log("required validation")
    errors.shortTripThresHoldValue = messages.required;
  } else if (/[a-zA-Z]/.test(values.shortTripThresHoldValue)) {
    console.log("chracter validation")
    errors.shortTripThresHoldValue = messages.characterError;
  } else if (values.shortTripThresHoldValue.length > 5) {
    console.log("limit validation")
    errors.shortTripThresHoldValue = messages.limitExceedError;
  } else if (!/^-?\d*\.?\d+$/.test(values.shortTripThresHoldValue) || values.shortTripThresHoldValue < 0) {
    console.log("float validation")
    errors.shortTripThresHoldValue = messages.floatValueError;
  }

  return errors
}

export default validate;
