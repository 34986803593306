import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';

import {
  SET_CASH_PAYOUT_START,
  SET_CASH_PAYOUT_SUCCESS,
  SET_CASH_PAYOUT_ERROR,
} from '../../../constants';

export function updateCashPayout(id, payoutStatus, currentPage, payoutId, changeLoaderState) {

  return async (dispatch, getState, { client }) => {

    dispatch({
      type: SET_CASH_PAYOUT_START,
    });

    try {
      let getPayoutList = gql`
      query getPayoutList($currentPage: Int, $searchList: String, $payoutId: String){
        getPayoutList(currentPage: $currentPage, searchList:$searchList, payoutId: $payoutId){
         count
          bookingData{
            id
            tripStatus
            currency
            driverDetails{
              firstName
              userData{
                email
              }
            }
            totalFare
            isPayoutPaid
            isBanStatus
            paymentType
            driverTotalFare
          }
        }
      }
      `

      let mutation = gql`
        mutation updateCashPayout ($id: Int, $payoutStatus: Boolean){
          updateCashPayout(id: $id, payoutStatus: $payoutStatus){
              status
            }
        }`;

      const { data } = await client.mutate({
        mutation,
        variables: { id, payoutStatus },
        refetchQueries: [{ query: getPayoutList, variables: { currentPage, searchList: '', payoutId } }],
      });

      if (data.updateCashPayout.status == 200) {
        if (changeLoaderState) changeLoaderState('successUpdateStatus', id);

        dispatch({
          type: SET_CASH_PAYOUT_SUCCESS,
        });

        toastr.success("Success!", "Payout status has been updated for the Cash trip");

      } else {

        dispatch({
          type: SET_CASH_PAYOUT_ERROR
        });

        toastr.error("Oops!", "Failed to update status for Cash Payout");
        if (changeLoaderState) changeLoaderState('removeUpdateStatus', id);

        return false;
      }
    } catch (error) {

      dispatch({
        type: SET_CASH_PAYOUT_ERROR
      });
      if (changeLoaderState) changeLoaderState('removeUpdateStatus', id);

      toastr.error("Oops!", "Failed to update status for Cash Payout");

      return false;
    }
    return true;
  };
}
