import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import {
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  FormControl
} from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import { connect } from 'react-redux';
import { Field, FieldArray, formValueSelector, change, reduxForm } from 'redux-form';
import DatePicker from '../../../../Common/DatePicker';
//local
import messages from '../../../../../locale/messages';
import closeIcon from '../../../../../../public/Icons/surgeDelete.svg';
import { getWeekDays, generateTimes } from '../../../../../helpers/surgePricing';
import addIcon from '../../../../../../public/Icons/surgeAdd.svg';
import s from './SurgePricing.css';
import bt from '../../../../../components/commonStyle.css';


class SurgePricing extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      weekDay: []
    };
    this.handlePricingType = this.handlePricingType.bind(this);
  }

  UNSAFE_componentWillMount() {
    let weekDay = getWeekDays();
    this.setState({
      weekDay
    });
  }

  async handlePricingType(index, value) {
    const { surgePricing, change } = this.props;
    if (value == 2) await change(`surgePricing[${index}].]dayPricingValue`, "Sunday")
  }

  renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder, maxLength }) => {
    const { formatMessage } = this.props.intl;
    return (
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <Form.Control {...input} placeholder={placeholder} type={type} className={fieldClass} maxLength={maxLength} />
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </Form.Group>
    )
  };

  renderFieldApp = ({ input, label, type, meta: { touched, error }, className, maxlength }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div className={cx('inputFormAddon', 'addonBorder')}>
        <Form.Group>
          <label className={cx(bt.labelText, s.normalFontWeight)} >{label}</label>
          <InputGroup>
            <FormControl {...input} placeholder={formatMessage(messages.valueContent)} type={type} className={bt.formControlInput} maxlength={maxlength} />
            <InputGroup.Append>
              <InputGroup.Text>
                x
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
          {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
        </Form.Group>
      </div>
    )
  }


  renderDate = ({ input, meta: { touched, error }, placeholder, formName, name, initialDate, index, fieldName }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div className={s.starSize}>
        <DatePicker
          placeholder={placeholder}
          formName={formName}
          fieldName={fieldName}
          initialDate={initialDate}
          index={index}
        />
        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
      </div>
    )
  };

  renderSelectField = ({ input, meta: { touched, error }, className, children }) => {
    const { formatMessage } = this.props.intl;
    return (
      <Form.Group>
        <Form.Control as="select" {...input} className={className} >
          {children}
        </Form.Control>
        {touched && error && <span className={cx(s.errorMessage, s.errorMessageSection)}>{formatMessage(error)}</span>}
      </Form.Group>
    )
  }

  renderMultiField = ({ fields }) => {
    const { surgePricing } = this.props;
    const { weekDay } = this.state;
    const { formatMessage } = this.props.intl;
    let startTimeLookups = generateTimes(0, 1410);
    let endTimeLookups = generateTimes(0, 0);

    return (
      <div>
        {fields.map((item, index) => (
          <div key={index} className={s.borderBottom}>
            <Row className={cx(s.positionRelativ, s.surgePricingRow)} >

              <Col lg={6} md={12} sm={12} xs={12}>
                <Form.Group className={s.formGroup}>
                  <div>
                    <label className={bt.labelText} >{formatMessage(messages.pricingType)}</label>
                    <Field name={`[${item}].pricingType`} className={cx(bt.formControlSelect, bt.formControlInput)} component="select" onChange={(e) => this.handlePricingType(index, e && e.target && e.target.value)}>
                      <option value={1}>{formatMessage(messages.pricingOption1)}</option>
                      <option value={2}>{formatMessage(messages.pricingOption2)}</option>
                    </Field>
                  </div>
                </Form.Group>
              </Col>

              {surgePricing[index] && surgePricing[index].pricingType == 2 && <Col lg={6} md={12} sm={12} xs={12}>
                <Form.Group className={s.formGroup}>
                  <div>
                    <label className={bt.labelText} >{formatMessage(messages.dayPricingValue)}</label>
                    <Field name={`[${item}].dayPricingValue`} className={cx(bt.formControlSelect, bt.formControlInput)} component="select">
                      {weekDay &&
                        weekDay.length > 0 &&
                        weekDay.map((item2, index2) => {
                          return (
                            <option value={item2}>{formatMessage(messages[item2])}</option>
                          );
                        })}
                    </Field>
                  </div>
                </Form.Group>
              </Col>}

              {surgePricing[index] && surgePricing[index].pricingType == 1 && <Col lg={6} md={12} sm={12} xs={12}>
                <Form.Group className={s.formGroup}>
                  <div>
                    <label className={bt.labelText} >{formatMessage(messages.datePricingValue)}</label>
                    <Field
                      placeholder={formatMessage(messages.datePricingValue)}
                      formName={'PricingForm'}
                      name={`[${item}].datePricingValue`}
                      initialDate={surgePricing[index].datePricingValue}
                      index={index}
                      component={this.renderDate}
                      fieldName={`[${item}].datePricingValue`}
                    />
                  </div>
                </Form.Group>
              </Col>}

              <Col lg={6} md={12} sm={12} xs={12}>
                <Form.Group className={s.formGroup}>
                  <div>
                    <label className={bt.labelText} >{formatMessage(messages.startTime)}</label>

                    <Field
                      name={`[${item}].startTime`}
                      component={this.renderSelectField}
                      label={formatMessage(messages.startTime)}
                      className={cx(bt.formControlSelect, bt.formControlInput)}
                    >
                      {
                        startTimeLookups && startTimeLookups.length > 0 && startTimeLookups.map((item, key) => {
                          return (<option key={key} value={item.value}>{item.label}</option>)
                        })
                      }
                    </Field>
                  </div>
                </Form.Group>
              </Col>

              <Col lg={6} md={12} sm={12} xs={12}>
                <Form.Group className={s.formGroup}>
                  <div>
                    <label className={bt.labelText} >{formatMessage(messages.endTime)}</label>
                    <Field
                      name={`[${item}].endTime`}
                      component={this.renderSelectField}
                      label={formatMessage(messages.endTime)}
                      className={cx(bt.formControlSelect, bt.formControlInput)}
                    >
                      {
                        endTimeLookups && endTimeLookups.length > 0 && endTimeLookups.map((item, key) => {
                          return (<option key={key} value={item.value}>{item.label}</option>)
                        })
                      }
                    </Field>
                  </div>
                </Form.Group>
              </Col>

              <Col lg={6} md={12} sm={12} xs={12}>
                <Form.Group className={s.formGroup}>
                  <div>
                    <Field name={`[${item}].surgeValue`} type="text" component={this.renderFieldApp} label={formatMessage(messages.surgeValue)} maxlength={10} />
                  </div>
                </Form.Group>
              </Col>

              <Col lg={6} md={12} sm={12} xs={12}>
                <Form.Group className={s.formGroup}>
                  <div>
                    <label className={bt.labelText} >{formatMessage(messages.status)}</label>
                    <Field name={`[${item}].isEnable`} className={cx(bt.formControlSelect, bt.formControlInput)} component="select">
                      <option value={"true"}>{formatMessage(messages.Enable)}</option>
                      <option value={"false"}>{formatMessage(messages.Disable)}</option>
                    </Field>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <div className='textAlignRightRTL'>
              <div className={cx(s.displayInline, s.margin, 'surgeMarginRTL')}>
                <Button className={cx(s.addItems, 'textWhite', 'svgImg', 'btnDarktransparent')} onClick={() => fields.push({
                  pricingType: 1,
                  dayPricingValue: null,
                  datePricingValue: null,
                  startTime: "0",
                  endTime: "0.5",
                  surgeValue: null,
                  isEnable: "true",
                })}>
                  <img src={addIcon} /> <span className={s.underLine}>{formatMessage(messages.add)}</span>
                </Button>
              </div>
              {fields && fields.length > 1 && <div className={s.displayInline}>
                <Button onClick={() => fields.remove(index)} className={cx(s.addItems, 'textWhite', 'svgImg', 'btnDarktransparent')}>
                  <img src={closeIcon} />
                  <span className={cx(s.underLine, s.marginLeft, 'surgeRightRTL')}>{formatMessage(messages.deleteAction)}</span>
                </Button>
              </div>}
            </div>
          </div>
        ))
        }

      </div >
    )
  }

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <div>
          <div className={s.headingMobile}>{formatMessage(messages.surgePriceDetails)}</div>
          <FieldArray
            name="surgePricing"
            rerenderOnEveryChange={true}
            component={this.renderMultiField}
          />
        </div>
      </div>
    );
  }
}

SurgePricing = reduxForm({
  form: 'PricingForm',
  destroyOnUnmount: false
})(SurgePricing);


const selector = formValueSelector('PricingForm');

const mapState = (state) => ({
  surgePricing: selector(state, 'surgePricing')
});

const mapDispatch = {
  change
};

export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(SurgePricing)));
