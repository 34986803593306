import gql from 'graphql-tag';
import { api } from '../../config';
import { toastr } from 'react-redux-toastr';
import {
  CAR_IMAGE_UPLOAD_ERROR,
  CAR_IMAGE_UPLOAD_START,
  CAR_IMAGE_UPLOAD_SUCCESS
} from '../../constants/index'

export default function uploadVehicleCarFrontImage(id, fileName, oldFile) {

  return async (dispatch, getState, { client }) => {
    dispatch({
      type: CAR_IMAGE_UPLOAD_START
    })

    try {
      const mutation = gql`
            mutation uploadVehicleCarFrontImage($id: Int, $vehicleCarFront: String) {
              uploadVehicleCarFrontImage(id:$id, vehicleCarFront:$vehicleCarFront) {
                  status
                  errorMessage
                }
              }
            `
      const { data } = await client.mutate({
        mutation,
        variables: {
          id,
          vehicleCarFront: fileName
        }
      })

      if (oldFile !== undefined) {
        removeCarImage(oldFile);
      };

      if (data && data.uploadVehicleCarFrontImage && data.uploadVehicleCarFrontImage.status == "200") {
        dispatch({
          type: CAR_IMAGE_UPLOAD_SUCCESS
        })
        toastr.success('Success!', 'The vehicle image has been updated successfully.');
      } else {
        toastr.error('Error!', "Oops, something went wrong, please try again later");
        dispatch({
          type: CAR_IMAGE_UPLOAD_ERROR
        })
      }
    } catch (err) {
      toastr.error('Error!', err.message || "Oops, something went wrong, please try again later");
      dispatch({
        type: CAR_IMAGE_UPLOAD_ERROR
      })
    }
  }
}

async function removeCarImage(fileName) {
  try {
    const url = api.apiEndpoint + "/deleteCarImage";
    const resp = await fetch(url, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fileName }),
    });

    const { status } = await resp.json();

    if (status) {
      return {
        status
      };
    }

  } catch (err) {
    console.log(err);
  }
}