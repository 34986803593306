import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import axios from 'axios';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as FontAwesome from 'react-icons/lib/fa';

//Components
import Loader from "../../components/Common/Loader/Loader";
import NotFound from "../NotFound/NotFound";
import CurrencyConverter from '../../components/CurrencyConverter/CurrencyConverter';
import RiderDetails from './RiderDetails';
import messages from "../../locale/messages";
import TripDetails from "./TripDetails";

//Config
import { api, logoUploadDir } from '../../config';

//Graphql
import checkIfBookingIsValid from './checkIfBookingIsValid.graphql';

//Images
import wallet from '../../../public/Icons/wallet.svg';

class PaypalPayment extends React.Component {
    createOrder = async () => {
        try {
            const { id, paymentCurrency, token, type } = this.props;
            const orderRes = await axios.post("/create-paypal-order", {
                id,
                paymentCurrency,
                token,
                type
            }, {
                headers: {
                    "Content-Type": "application/json",
                }
            })

            if (orderRes.data.status !== "CREATED") {
                window.location = "/paymentfailure?errorMessage=" + orderRes.data.errorMessage;
            }

            return orderRes?.data?.createOrder?.id;
        }
        catch (error) {
            window.location = "/paymentfailure?errorMessage=" + error.message;
        }

    }

    onApprove = async (data) => {
        const { type } = this.props;
        try {
            const captureRes = await axios.post("/capture-paypal-order", { orderId: data && data.orderID, type }, {
                headers: {
                    "Content-Type": "application/json",
                }
            });

            if (captureRes.data.status !== 200 && !captureRes.data.result) {
                window.location = "/paymentfailure?errorMessage=" + captureRes.data.errorMessage;
            }

            window.location = captureRes.data.result.redirectURL;
        } catch (error) {
            window.location = "/paymentfailure?errorMessage=" + error.message;
        }
    }
    render() {
        const { validateBooking: { loading, checkIfBookingIsValid }, paymentCurrency, logo, siteName, logoHeight, logoWidth } = this.props
        const { locale, type, theme } = this.props;

        const initialOptions = {
            clientId: checkIfBookingIsValid && checkIfBookingIsValid.result && checkIfBookingIsValid.result[type] && checkIfBookingIsValid.result[type].clientId,
            components: "buttons",
            currency: paymentCurrency
        };

        if (loading) return <Loader type={"text"} />

        if (checkIfBookingIsValid && checkIfBookingIsValid.status !== 200) return <NotFound />

        return (
            <div>
                <img
                    src={api.apiEndpoint + logoUploadDir + logo}
                    width={Number(logoWidth)}
                    height={Number(logoHeight)}
                    alt={siteName}
                />
                {
                    type == "booking" ?
                        <>
                            <RiderDetails
                                data={checkIfBookingIsValid.result && checkIfBookingIsValid.result[type]}
                                siteName={siteName}
                            />
                            <FormattedMessage {...messages.tripAmountLabel} />
                            <CurrencyConverter
                                from={checkIfBookingIsValid && checkIfBookingIsValid.result && checkIfBookingIsValid.result[type].currency}
                                amount={checkIfBookingIsValid && checkIfBookingIsValid.result && checkIfBookingIsValid.result[type].riderPayableFare}
                                to={paymentCurrency}
                            />
                            <FontAwesome.FaCheckCircle />
                            <TripDetails
                                data={checkIfBookingIsValid.result && checkIfBookingIsValid.result[type]}
                                siteName={siteName}
                                locale={locale}
                                to={paymentCurrency}
                            />
                        </>
                        :
                        <div>
                            <img
                                src={wallet}
                                width={Number(logoWidth)}
                                height={Number(logoHeight)}
                                alt={siteName}
                            />
                            <FormattedMessage {...messages.yourWalletAmount} />
                            <CurrencyConverter
                                from={checkIfBookingIsValid && checkIfBookingIsValid.result[type] && checkIfBookingIsValid.result[type].currency}
                                amount={checkIfBookingIsValid && checkIfBookingIsValid.result[type] && checkIfBookingIsValid.result[type].amount}
                                to={paymentCurrency}
                            />
                        </div>
                }

                <PayPalScriptProvider options={initialOptions}>
                    <PayPalButtons
                        fundingSource="paypal"
                        style={{ "layout": "vertical" }}
                        disabled={false}
                        forceReRender={[{ "layout": "vertical" }]}
                        createOrder={this.createOrder}
                        onApprove={this.onApprove}
                    />
                </PayPalScriptProvider>
            </div>
        )
    }
};

const mapToState = state => ({
    logo: state.siteSettings.data.homeLogo,
    siteName: state.siteSettings.data.siteName,
    logoHeight: state.siteSettings.data.logoHeight,
    logoWidth: state.siteSettings.data.logoWidth,
    locale: state.intl.locale,
    theme: state.currency.theme
});

const mapToDispatch = {};

export default compose(
    injectIntl,
    connect(mapToState, mapToDispatch),
    graphql(checkIfBookingIsValid, {
        name: "validateBooking",
        options: (props) => ({
            variables: {
                id: props.id || 0,
                token: props.token || "",
                radom: props.radom,
                type: props.type
            }
        })
    }))(PaypalPayment)