import React, { Component } from 'react'
import { connect } from 'react-redux'
import withStyles from 'isomorphic-style-loader/withStyles';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import {
  Button,
  Row,
  FormGroup,
  Col,
  FormControl,
  Container,
  Form,
  InputGroup
} from 'react-bootstrap';
import cx from 'classnames';
import s from './ConfigSettingsForm.css';
import bt from '../../../components/commonStyle.css';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages'
import submit from './submit'
import validate from './validate'
import Loader from '../../Common/Loader/Loader';


export class ConfigSettingsForm extends Component {
  static defaultProps = {
    loading: false
  };


  renderFormControl = ({ input, label, type, meta: { touched, error }, className, maxlength }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormGroup className={s.formGroup}>
          <div>
            <label className={cx(bt.labelText, s.normalFontWeight)} >{label}</label>
          </div>
          <div>
            <FormControl {...input} placeholder={label} type={type} className={bt.formControlInput} maxlength={maxlength} />
            {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
          </div>
        </FormGroup>
      </div>
    );
  }

  renderFormControlSelect = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <div>
          <label className={cx(bt.labelText, s.normalFontWeight)} >{label}</label>
        </div>
        <FormControl as="select" {...input} className={className} >
          {children}
        </FormControl>
        {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
      </div>
    )
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { handleSubmit, logo, loading, submitting, appForceUpdate, tripTone, deleteTripTone, tripToneFile } = this.props;
    const waitingTime = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    return (
      <div>
        <div className={s.paddingTop}>
          <Container fluid>
            <form onSubmit={handleSubmit(submit)}>

              <h5 className={cx(s.headingMobile, 'headingMobileRTL')}>{formatMessage(messages.stripeSettings)}</h5>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="stripePublishableKey" type="text" component={this.renderFormControl} label={formatMessage(messages.stripePublishableKey)} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="stripeSecretKey" type="text" component={this.renderFormControl} label={formatMessage(messages.stripeSecretKey)} />
                </Col>
              </Row>

              <h5 className={cx(s.headingMobile, 'headingMobileRTL')}>{formatMessage(messages.twilio)}</h5>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="twillioAccountSid" type="text" component={this.renderFormControl} label={formatMessage(messages.twillioAccountSid)} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="twillioAuthToken" type="text" component={this.renderFormControl} label={formatMessage(messages.twillioAuthToken)} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="twillioPhone" type="text" component={this.renderFormControl} label={formatMessage(messages.twillioPhone)} />
                </Col>
              </Row>

              <h5 className={cx(s.headingMobile, 'headingMobileRTL')}>{formatMessage(messages.smtpSettings)}</h5>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="smtpHost" type="text" component={this.renderFormControl} label={formatMessage(messages.smtpHost)} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="smtpPort" type="text" component={this.renderFormControl} label={formatMessage(messages.smtpPort)} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="smptEmail" type="text" component={this.renderFormControl} label={formatMessage(messages.smptEmail)} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="smtpSender" type="text" component={this.renderFormControl} label={formatMessage(messages.smtpSender)} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="smtpSenderEmail" type="text" component={this.renderFormControl} label={formatMessage(messages.smtpSenderEmail)} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="smtpPassWord" type="text" component={this.renderFormControl} label={formatMessage(messages.smtpPassWord)} />
                </Col>
              </Row>

              <h5 className={cx(s.headingMobile, 'headingMobileRTL')}>{formatMessage(messages.otherSettings)}</h5>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="maxDistance" type="text" component={this.renderFormControl} label={formatMessage(messages.allowableDistaceTitle) + ' (KM)'} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="pushNotificationKey" type="text" component={this.renderFormControl} label={formatMessage(messages.pushNotificationKey)} />
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="paypalEmail" type="text" component={this.renderFormControl} label={formatMessage(messages.paypalEmailLabel)} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="paypalClientId" type="text" component={this.renderFormControl} label={formatMessage(messages.paypalClientId)} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="paypalSecret" type="text" component={this.renderFormControl} label={formatMessage(messages.paypalSecret)} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="paypalHost" type="text" component={this.renderFormControl} label={formatMessage(messages.paypalHost)} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field
                    name="paypalHostMode"
                    className={cx(s.adminUserSelect, bt.formControlSelect)}
                    component={this.renderFormControlSelect}
                    label={formatMessage(messages.paypalHostMode)}
                  >
                    <option value={'live'}>{formatMessage(messages.liveMode)}</option>
                    <option value={'sandbox'}>{formatMessage(messages.sandboxMode)}</option>
                  </Field>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="shortTripThresHoldValue" type="text" component={this.renderFormControl} label={formatMessage(messages.shortTripThresHoldValue)} />
                </Col>
              </Row>

              <Col lg={12} md={12} sm={12} xs={12} className={cx(bt.textAlignRight, bt.spaceTop3, 'textAlignLeftRTL', 'loadingBtnRTL')}>
                <FormGroup className={s.formGroup}>
                  <div>
                    <Loader
                      type={"button"}
                      label={formatMessage(messages.submitButton)}
                      show={loading}
                      buttonType={'submit'}
                      className={cx(bt.btnPrimary)}
                      disabled={submitting || loading}
                      isSuffix={true}
                    />
                  </div>
                </FormGroup>
              </Col>

            </form>
          </Container>
        </div>
      </div>
    )
  }
}

ConfigSettingsForm = reduxForm({
  form: 'ConfigSettingsForm',
  onSubmit: submit,
  validate,
})(ConfigSettingsForm);

const selector = formValueSelector('ConfigSettingsForm')

const mapState = (state) => ({
  loading: state.loader.ConfigSettings,
})
const mapDispatch = {
}
export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(ConfigSettingsForm)));
