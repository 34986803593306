import { toastr } from "react-redux-toastr";
import gql from "graphql-tag";
import {
    ADD_ADMIN_EMAIL_START,
    ADD_ADMIN_EMAIL_SUCCESS,
    ADD_ADMIN_EMAIL_ERROR
} from "../../../constants"

const mutation = gql`
 mutation ($email: String) {
   createAdminEmail(email: $email) {
     status
    errorMessage
}} `;

const query = gql`
query{
    getAllAdminEmail{
        results{
            email
        }
        status
        errorMessage
    }
}
`

export const manageAdminEmail = ({ email }) => {
    return async (dispatch, getState, { client }) => {

        try {
            dispatch({
                type: ADD_ADMIN_EMAIL_START,
            });

            const { data } = await client.mutate({
                mutation,
                variables: {
                    email: JSON.stringify(email)
                },
                refetchQueries: [{ query }]
            });

            if (data?.createAdminEmail?.status == 200) {
                toastr.success('Success', `The email address has been successfully added.`);
                await dispatch({
                    type: ADD_ADMIN_EMAIL_SUCCESS
                });

            } else {
                toastr.error('Error!', data?.uploadShopDocument?.errorMessage);
                await dispatch({
                    type: ADD_ADMIN_EMAIL_ERROR,
                    payload: {
                        errorMessage: data?.uploadShopDocument?.errorMessage
                    }
                });
            }
        } catch (error) {
            toastr.error('Error!', `Something went wrong! ${error.message}`);
            await dispatch({
                type: ADD_ADMIN_EMAIL_ERROR,
                payload: {
                    errorMessage: error
                }
            });
        }
    }
}
