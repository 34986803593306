import { toastr } from "react-redux-toastr";

export const validateTag = (tag, existingTags) => {
    if (tag) {
        const register = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(tag);
        if (!register) {
            toastr.error("Error", "Please add a valid email address")
            return false;
        }
        return true;
    }
};

export const getAllEmail = ({ getAllAdminEmail }) => {
    try {
        const email = getAllAdminEmail?.results.filter((item) => {
            if (item) {
                return item;
            }
        }).map((item) => item.email)

        return email;
    } catch (error) {
        console.log(error)
    }
}
