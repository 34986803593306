import gql from 'graphql-tag';
import { toastr } from 'react-redux-toastr';
import { change } from 'redux-form';

import {
	MOBILE_SETTINGS_UPLOAD_START,
	MOBILE_SETTINGS_UPLOAD_ERROR,
	MOBILE_SETTINGS_UPLOAD_SUCCESS
} from '../../constants/index';
import { setLoaderStart, setLoaderComplete } from '../loader/loader';
import { api } from '../../config';


export function updateMobileSettings(
	appForceUpdate, riderAndroidVersion,
	riderIosVersion, driverAndroidVersion, driverIosVersion, multipleStopsWaitingTime, preferredDistanceType,
	distance, duration, estimatedPrice, pickupLocation, destinationLocation, sleepDriverAndroid, sleepDriverios, contactPhoneNumber, contactEmail, skype, startRideRadius,
	tripTimeTone, isTripTimerToneEnable, openAppOnTrip, tripToneFile, maxWaitingTime, notificationInterval
) {
	return async (dispatch, getState, { client }) => {
		dispatch({
			type: MOBILE_SETTINGS_UPLOAD_START
		})

		try {

			const mutation = gql`
				mutation updateMobileSettings(
					$appForceUpdate: String
					$riderAndroidVersion: String
					$riderIosVersion: String
					$driverAndroidVersion: String
					$driverIosVersion: String,
					$multipleStopsWaitingTime: String,
					$preferredDistanceType: String,
					$distance: String
					$duration: String				
					$estimatedPrice: String
					$pickupLocation: String
					$destinationLocation: String
					$sleepDriverAndroid: String
					$sleepDriverios: String
					$contactPhoneNumber: String
					$contactEmail: String
					$skype: String
					$startRideRadius: String,
					$tripTimeTone: String
					$isTripTimerToneEnable: String
					$openAppOnTrip: String
					$tripToneFile: String
					$maxWaitingTime: String
					$notificationInterval: String
					) {
						updateMobileSettings(
						appForceUpdate: $appForceUpdate
						riderAndroidVersion: $riderAndroidVersion
						riderIosVersion: $riderIosVersion
						driverAndroidVersion: $driverAndroidVersion
						driverIosVersion: $driverIosVersion,
						multipleStopsWaitingTime: $multipleStopsWaitingTime,
						preferredDistanceType: $preferredDistanceType,
						distance: $distance,
						duration: $duration,
						estimatedPrice: $estimatedPrice,
						pickupLocation: $pickupLocation,
						destinationLocation: $destinationLocation,
						sleepDriverAndroid: $sleepDriverAndroid,
						sleepDriverios: $sleepDriverios,
						contactPhoneNumber: $contactPhoneNumber,
						contactEmail: $contactEmail,
						skype: $skype
						startRideRadius: $startRideRadius
						tripTimeTone: $tripTimeTone
						isTripTimerToneEnable: $isTripTimerToneEnable
						openAppOnTrip: $openAppOnTrip
						tripToneFile: $tripToneFile
						maxWaitingTime: $maxWaitingTime
						notificationInterval: $notificationInterval
						){
						status
						}
					}
				`
			dispatch(setLoaderStart('MobileSettings'))
			const { data } = await client.mutate({
				mutation,
				variables: {
					appForceUpdate,
					riderAndroidVersion,
					riderIosVersion,
					driverAndroidVersion,
					driverIosVersion,
					multipleStopsWaitingTime,
					preferredDistanceType,
					distance,
					duration,
					estimatedPrice,
					pickupLocation,
					destinationLocation,
					sleepDriverAndroid,
					sleepDriverios,
					contactPhoneNumber,
					contactEmail,
					skype,
					startRideRadius,
					tripTimeTone,
					isTripTimerToneEnable,
					openAppOnTrip,
					tripToneFile,
					maxWaitingTime,
					notificationInterval
				}
			})

			dispatch(setLoaderComplete('MobileSettings'))
			if (data && data.updateMobileSettings && data.updateMobileSettings.status == 200) {

				dispatch({
					type: MOBILE_SETTINGS_UPLOAD_SUCCESS
				})
				toastr.success('Success', "The mobile app's settings have been updated!")
			} else {
				dispatch({
					type: MOBILE_SETTINGS_UPLOAD_ERROR
				})
				toastr.error('Oops!', 'Something went wrong')
			}
		} catch (err) {
			dispatch({
				type: MOBILE_SETTINGS_UPLOAD_ERROR
			})
			toastr.error('Oops! Something went wrong', err)
		}

	}
}

export function deleteTripTone(fileName) {
	return async (dispatch, getState, { client }) => {
		dispatch({
			type: MOBILE_SETTINGS_UPLOAD_START
		})

		try {

			dispatch(change("MobileSettingsForm", "tripTimeTone", null));

			const url = api.apiEndpoint + "/deleteTripTone";
			const resp = await fetch(url, {
				method: 'post',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ fileName }),
			});

			const { status } = await resp.json();

			if (status == 200) {

				const mutation = gql`
				mutation updateTripTone {
						updateTripTone {
						status
						}
					}
				`
				const { data } = await client.mutate({
					mutation
				})

				dispatch({
					type: MOBILE_SETTINGS_UPLOAD_SUCCESS
				})

			} else {
				dispatch({
					type: MOBILE_SETTINGS_UPLOAD_ERROR
				})
			}

		} catch (err) {
			dispatch({
				type: MOBILE_SETTINGS_UPLOAD_ERROR
			})
			toastr.error('Oops! Something went wrong', err);
			return true;
		}
	}
}

export function updateConfigSettings(
	values
) {
	return async (dispatch, getState, { client }) => {
		dispatch({
			type: MOBILE_SETTINGS_UPLOAD_START
		})

		try {

			const mutation = gql`
				mutation updateConfigSettings(
					$stripePublishableKey: String
					$stripeSecretKey: String
					$twillioAccountSid: String
					$twillioAuthToken: String
					$twillioPhone: String,
					$maxDistance: String,
					$pushNotificationKey: String,
					$paypalEmail: String,
					$paypalClientId: String,
					$paypalSecret: String,
					$paypalHost: String,
					$paypalHostMode: String,
					$shortTripThresHoldValue: String,
					$smtpHost: String,
                    $smtpPort: String,
                    $smptEmail: String,
                    $smtpSender: String,
                    $smtpSenderEmail: String,
                    $smtpPassWord: String,
					) {
						updateConfigSettings(
							stripePublishableKey: $stripePublishableKey
							stripeSecretKey: $stripeSecretKey
							twillioAccountSid: $twillioAccountSid
							twillioAuthToken: $twillioAuthToken
							twillioPhone: $twillioPhone,
							maxDistance: $maxDistance,
							pushNotificationKey: $pushNotificationKey,
							paypalEmail: $paypalEmail,
							paypalClientId: $paypalClientId,
							paypalSecret: $paypalSecret,
							paypalHost: $paypalHost,
							paypalHostMode: $paypalHostMode,
							shortTripThresHoldValue: $shortTripThresHoldValue,
							smtpHost: $smtpHost,
                            smtpPort: $smtpPort,
                            smptEmail: $smptEmail,
                            smtpSender: $smtpSender,
                            smtpSenderEmail: $smtpSenderEmail,
                            smtpPassWord: $smtpPassWord,
						){
						status
						}
					}
				`
			dispatch(setLoaderStart('ConfigSettings'))
			const { data } = await client.mutate({
				mutation,
				variables: values
			})

			dispatch(setLoaderComplete('ConfigSettings'))
			if (data && data.updateConfigSettings && data.updateConfigSettings.status == 200) {

				dispatch({
					type: MOBILE_SETTINGS_UPLOAD_SUCCESS
				})
				toastr.success('Success', "The configuration settings have been updated!")
			} else {
				dispatch({
					type: MOBILE_SETTINGS_UPLOAD_ERROR
				})
				toastr.error('Oops!', 'Something went wrong')
			}
		} catch (err) {
			dispatch({
				type: MOBILE_SETTINGS_UPLOAD_ERROR
			})
			toastr.error('Oops! Something went wrong', err)
		}

	}
}
