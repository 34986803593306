import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import messages from '../../../locale/messages';
import { graphql } from 'react-apollo';
import Loader from '../../../components/Common/Loader/Loader';
import { flowRight as compose } from 'lodash';
import adminEmailQuery from './adminEmailQuery.graphql';
import AdminEmailForm from '../../../components/SiteAdmin/AdminEmailForm/AdminEmailForm';

import s from './AdminEmails.css';

class AdminEmails extends Component {
    static propTypes = {
        adminEmails: PropTypes.shape({
            loading: PropTypes.bool,
            getAllAdminEmail: PropTypes.array,
        })
    }

    render() {
        const { adminEmails: { loading, getAllAdminEmail } } = this.props;
        return (
            <div>
                <Loader type={"page"} show={loading}>
                    <div className={s.root}>
                        <div className={s.container}>
                            <div className={s.heading}>
                                <FormattedMessage {...messages.addAdminEmail} />
                            </div>
                            <div>
                                {
                                    !loading && <AdminEmailForm getAllAdminEmail={getAllAdminEmail} />
                                }
                            </div>
                        </div>
                    </div>
                </Loader>
            </div>
        )
    }
}

export default compose(withStyles(s),
    graphql(adminEmailQuery, {
        name: 'adminEmails',
        options: (props) => ({
            fetchPolicy: 'network-only',
            ssr: false
        })
    }),
)(AdminEmails);
