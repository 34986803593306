import { toastr } from 'react-redux-toastr';
import gql from 'graphql-tag';

import {
    UPDATE_ALLOW_CASH_PAYMENT_START,
    UPDATE_ALLOW_CASH_PAYMENT_SUCCESS,
    UPDATE_ALLOW_CASH_PAYMENT_ERROR
} from '../../constants';


const updateAllowCashPayment = ({ bookingId, isCashEnabled, changeLoaderState, refetch, variables }) => {
    return async (dispatch, getState, { client }) => {
        try {

            dispatch({ type: UPDATE_ALLOW_CASH_PAYMENT_START });

            const mutation = gql`
            mutation updateAllowCashPayment($bookingId: Int!, $isCashEnabled: Boolean) {
                updateAllowCashPayment(bookingId: $bookingId, isCashEnabled: $isCashEnabled) {
                  status
                  errorMessage
                }
            }`;

            const { data } = await client.mutate({
                mutation,
                variables: { bookingId, isCashEnabled: isCashEnabled == "true" }
            });

            if (data?.updateAllowCashPayment?.status !== 200) {
                dispatch({ type: UPDATE_ALLOW_CASH_PAYMENT_ERROR });
                if (changeLoaderState) changeLoaderState('removeUpdateStatus', bookingId);
                toastr.error("Error", data?.updateAllowCashPayment?.errorMessage || "Oops, something went wrong");
                return;
            };

            dispatch({ type: UPDATE_ALLOW_CASH_PAYMENT_SUCCESS });
            if (changeLoaderState) changeLoaderState('removeUpdateStatus', bookingId);
            refetch(variables);
            toastr.success("Success!", "updated successfully");

        } catch (error) {
            dispatch({ type: UPDATE_ALLOW_CASH_PAYMENT_ERROR });
            if (changeLoaderState) changeLoaderState('removeUpdateStatus', bookingId);
            toastr.error("Error", error.message || "Oops, something went wrong");
        }
    }
};

export default updateAllowCashPayment;