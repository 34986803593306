import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import * as FontAwesome from 'react-icons/lib/fa';

//Components
import messages from '../../locale/messages';
import StarRating from '../../components/StarRating';
import CurrencyConverter from '../../components/CurrencyConverter/CurrencyConverter';

//Config
import { profilePhotouploadDir, api } from '../../config';

class TripDetails extends Component {
    getFormattedDistance = (distance, distanceType) => {
        const { formatMessage } = this.props.intl;

        let unit = distanceType;
        if (distanceType === 'mile' && distance > 1) unit = 'miles'
        return `${distance} ${messages[unit] ? formatMessage(messages[unit]) : distanceType}`
    }
    renderAwaitingInformation = (label, data) => {
        return (
            <div>
                {label} {data}
                <FontAwesome.FaInfoCircle />
                <FormattedMessage {...messages.waitingFeeInfo} />
            </div>
        )
    }
    renderInformation = (label, data) => {
        return (
            <div>
                {label} {data}
            </div>
        )
    }
    render() {
        const { data, locale, to } = this.props
        return (
            <div>
                {data.totalDuration && this.renderInformation(<FormattedMessage {...messages.duration} />, moment.duration({ "minutes": data.totalDuration }).locale(locale).humanize())}
                {(data.totalRideDistance && data.distanceType) && this.renderInformation(<FormattedMessage {...messages.distance} />, this.getFormattedDistance(data.totalRideDistance, data.distanceType))}
                {data.startTime && this.renderInformation(<FormattedMessage {...messages.timeRequestedLabel} />, moment(data.startTime).format("hh:mm A"))}
                {data.startTime && this.renderInformation(<FormattedMessage {...messages.pricingOption1} />, moment(data.startTime).format("MMM d, yyyy"))}
                {data.totalFare > 0 && this.renderInformation(<FormattedMessage {...messages.fareLabel} />,
                    <CurrencyConverter
                        from={data.currency}
                        amount={data.totalFare}
                        to={to}
                    />
                )}
                {data.specialTripPrice > 0 && this.renderInformation(<FormattedMessage {...messages.discount} />,
                    <CurrencyConverter
                        from={data.currency}
                        amount={data.specialTripPrice}
                        to={to}
                    />
                )}
                {data.riderServiceFee > 0 && this.renderInformation(<FormattedMessage {...messages.bookingFee} />,
                    <CurrencyConverter
                        from={data.currency}
                        amount={data.riderServiceFee}
                        to={to}
                    />
                )}
                {data.waitingPrice > 0 && this.renderAwaitingInformation(<FormattedMessage {...messages.waitingPrice} />,
                    <CurrencyConverter
                        from={data.currency}
                        amount={data.waitingPrice}
                        to={to}
                    />
                )}
                {data.tipsAmount > 0 && this.renderInformation(<FormattedMessage {...messages.tipsLabel} />,
                    <CurrencyConverter
                        from={data.currency}
                        amount={data.tipsAmount}
                        to={to}
                    />
                )}
                {data.tollFee > 0 && this.renderInformation(<FormattedMessage {...messages.totalChargesLabel} />,
                    <CurrencyConverter
                        from={data.currency}
                        amount={data.tollFee}
                        to={to}
                    />
                )}
                {
                    data.riderPayableFare > 0 && this.renderInformation(<FormattedMessage {...messages.total} />,
                        <CurrencyConverter
                            from={data.currency}
                            amount={data.riderPayableFare}
                            to={to}
                        />
                    )
                }
            </div>
        )
    }
};

export default injectIntl(TripDetails);