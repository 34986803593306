import React from "react";
import PaypalPayment from "../../components/PaypalPayment/PaypalPayment";

class PaymentPaypal extends React.Component {
    render() {
        const { id, paymentCurrency, token, type, radom } = this.props;

        return (
            <div>
                <PaypalPayment
                    id={id}
                    paymentCurrency={paymentCurrency}
                    token={token}
                    type={type}
                    radom={radom}
                />
            </div>
        )
    }
};


export default PaymentPaypal