
import React from 'react';

//Components
import PaymentPaypal from './PaymentPaypal';
import Layout from '../../components/Layout';
import Toaster from '../../components/Toaster';

async function action({ query }) {
  return {
    title: "Payment Paypal",
    component: (
      <Layout
        isPayalHeader={true}
      >
        <Toaster />
        <PaymentPaypal
          id={query.id}
          paymentCurrency={query.paymentcurrency}
          token={query.token}
          type={query.type}
          radom={query.random || null}
        />
      </Layout>
    )
  };
}

export default action;
